import { Checkbox, styled } from "@mui/material";


const BpCheckedIcon = styled(Checkbox)(({ theme }) =>({
    color: theme.palette.background.default,
    '&.Mui-checked' : {
        color: theme.palette.background.default
    },
  }));

  export const NotifyCheckbox = ({checked, onChange})=> {
    return <BpCheckedIcon checked={checked} onChange={onChange} />
  }

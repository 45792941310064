import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initialState = {
    isAuthUser: false,
    authToken: null
};

export const authSlice = createSlice({
    name: 'authUser',
    initialState,
    reducers: {
        setAuthDetails (state, {payload}) {
            console.log(payload);
            state.authToken = payload.authToken;
            state.isAuthUser = payload.authUser;
        }
    }
});

export const {
    setAuthDetails
} = authSlice.actions;
export default authSlice.reducer;
import React from 'react';
import './Footer.css';
import { FaRegCopyright } from 'react-icons/fa';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className='footer_container'>
      <p className="tc_footer">
        <span><FaRegCopyright /></span> Copyright {currentYear} Letnotify. All rights reserve. Various
        trademarks held by their respective owners.

      </p>
    </div>
  );
}

export default Footer;

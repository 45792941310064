import styled from "@emotion/styled";
import { FormHelperText, Input } from "@mui/material";

const ariaLabel = { "aria-label": "description" };

const CustomInput = styled(Input)(({ theme }) => ({
  color: theme.palette.grey["400"],
  width: "100%",
  "&::after": {
    border: "1px solid #fff",
    backgroundColor: "#004a40",
  },
  "&:hover": {
    backgroundColor: "#004a40",
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    padding: "0.75rem",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid rgba(255, 133, 51, 0.42)",
  },
}));

export const InputField = ({placeholder, style, onChange, name, type, error, helperText}) => {
  return (
    <>
      <CustomInput
        placeholder={placeholder}
        inputProps={ariaLabel}
        style={style}
        onChange={onChange}
        type={type}
        name={name}
        error={error}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );  
};

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./reducers/authReducer";

const persistConfig = {
    key : 'root',
    storage
};

const rootReducers = combineReducers({
    authReducer
});

const persistedReducers = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
    middleware : (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: persistedReducers
});

export const persistor = persistStore(store);
import styled from "@emotion/styled";
import { Box, Container, Select } from "@mui/material";

export const MainContainer = styled(Container)`
  width: 82vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
`;

export const QuestionStack = styled(Box)`
  width: 60vw;
  height: 100vh;
  padding: 0% 3%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  overflow-x: hidden;
  overflow: scroll;
`;

export const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.grey["400"],
  width: "100%",
  "&::after": {
    border: "1px solid #fff",
    backgroundColor: "#004a40",
  },
  "&:hover": {
    backgroundColor: "#004a40",
  },
  "& .MuiInputBase-input": {
    padding: "0.75rem",
  },
}));

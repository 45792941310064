import { Button, styled } from "@mui/material";

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
  padding: "0.5rem 2rem",
  "&:hover": {
    backgroundColor: "#046053",
    transition: "0.3s ease-out",
    transform: "scaleX(1)",
  },
}));

const Outlined = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  padding: "0.5rem 2rem",
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
    transition: "0.3s ease-out",
    transform: "scaleX(1)",
  },
}));
export const NotifyButton = ({ title, onClick, variant, sx }) => {
  return (
    <CustomButton onClick={onClick} variant={variant || "contained"} sx={sx}>
      {title}
    </CustomButton>
  );
};

export const OutlinedButton = ({ title, onClick, variant, sx }) => {
  return (
    <Outlined onClick={onClick} variant={variant || "outlined"} sx={sx}>
      {title}
    </Outlined>
  );
};

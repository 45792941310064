import React, { useEffect } from "react";
import "./styles/LandingPage.css";
import Typed from "typed.js";
import Pushnotifications from "../../../assets/Images/LandingPage/Pushnotifications.png";
import mobilemarketing from "../../../assets/Images/LandingPage/mobilemarketing.png";
import seo from "../../../assets/Images/LandingPage/seo.png";
import team from "../../../assets/Images/LandingPage/team.png";
import connectedworld from "../../../assets/Images/LandingPage/connectedworld.png";
import promoting from "../../../assets/Images/LandingPage/promoting.png";
import feedback from "../../../assets/Images/LandingPage/feedback.png";
import socialmedia from "../../../assets/Images/LandingPage/socialmedia.png";
import eventpromotion from "../../../assets/Images/LandingPage/eventpromotion.png";
import newCampaign from "../../../assets/Images/LandingPage/new_campaign.png";
import segments from "../../../assets/Images/LandingPage/segments.png";
import automation from "../../../assets/Images/LandingPage/automation.png";
import abtesting from "../../../assets/Images/LandingPage/abtesting.png";
import letsNotifyIcon from "../../../assets/Images/LandingPage/letsnotifyicon.png";
import connection from "../../../assets/Images/LandingPage/connection.png"
import { useLoadingContext } from "react-router-loading";
import LandingPageHeader from "./LandingPageHeader";
import LandingFooter from "./LandingFooter";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";

function LandingPage() {

  const loadingContext = useLoadingContext();
  setInterval(function () {
    loadingContext.done();
  }, 1000);

  // const navigate = useNavigate();
  const el = React.useRef(null);

  React.useEffect(() => {
    AOS.init();

    const typed = new Typed(el.current, {
      strings: [
        "<a>Drive Traffic!</a>",
        "<a>Engage!</a>",
        "<a>Monetize!</a>",
        "<a>Soar!</a>",
      ],
      typeSpeed: 50,
      loop: true,
      showCursor: false,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  {
    gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
      gsap.to(".main", {
        backgroundColor: "white",
        duration: 0.1,
        scrollTrigger: {
          trigger: ".hashtag",
          start: "top 2%",
          end: "top 0%",
          onLeaveBack: () => {
            gsap.to(".main", { duration: 0.5, backgroundColor: "#ECECEC" });
          },
          onEnter: () => {
            gsap.to(".main", { duration: 0.1, backgroundColor: "#fff" });
          },
        },
      });
    });
  }

  return (
    <>
      <div className="w-[99vw] overflow-hidden relative main bg-[#ECECEC] flex flex-col items-center">
        <LandingPageHeader />

        <div className="top_container h-[70vh] md:mb-0 mb-4 w-[90vw] md:w-[80vw] mt-[15vh] md:mt-[20vh] flex justify-between flex-col-reverse md:flex-row ">
          <div className="mt-4">
            <div className=" font-bold uppercase text-2xl md:mt-[2vw] md:text-[2.5vw] w-[100%] md:h-[60%] md:h-[60%]">
              <h1 className="leading-10">SuperCharge your Website Engagement.</h1>
              <h1 className="mt-2">
                <div className="mt-5 typed  !text-teal-700">
                  <span ref={el} />
                  <span> 🚀</span>
                </div>
              </h1>
              {/* <br /> */}
              {/* <h5 className="hashtag text-teal-700 mt-3 md:mt-5">
                #BloggerSuccess
              </h5> */}
              <h5 className="mt-3 text-[10px] md:text-sm">
                Seamless Web Push Notifications Software
              </h5>
              <h5 className="mt-2 text-[10px] md:text-sm">
                Tailored for <strong>You!</strong>
              </h5>
            </div>
            <div className="h-14 w-[100%] md:w-44 md:mt-0 mt-7 flex justify-center items-center rounded-md font-semibold bg-teal-600 cursor-pointer md:hover:bg-teal-800 transition-all text-white uppercase">
              {/* <input placeholder="Please add your email address" /> */}
              <Link to="https://docs.google.com/forms/d/e/1FAIpQLScAAYJZEj2FsUTrJ8RHNtEUXbPPcLEA87bqV8l8XiPI3yZrkQ/viewform">
                Get Early Access
              </Link>
            </div>
          </div>
          <div className="animatedBox2 ml-6 md:ml-0">
            <div className={`animatedBox`}>
              <img src={Pushnotifications} alt="logo" />
            </div>
          </div>
        </div>

        <div className="ai_engine_container" data-aos="zoom-in">
          <h3>Building Next Level Cross Channel Engagement</h3>
          <img src={connection} alt="ai_engine" className="ai_engine" /> 
        </div>

        {/* <div className="lp-about">
          <div className="lef">
            <h1>Get the Most Out From Us</h1>
            <br />
            <h5>
              Push notifications can be a powerful tool for bloggers to engage
              with their audience and drive traffic to their websites.
            </h5>
          </div>
          <div className="righ">
            <div className="picarrange">
              <div className="card image1">
                <img
                  src={mobilemarketing}
                  alt="logo"
                  className="mobile"
                  loading="lazy"
                />
                <div className="card-body">
                  <h1 className="card-title">Instant Communication</h1>
                  <p className="card-info">
                    Push notifications allow bloggers to send instant updates to
                    their subscribers. Whether it's a new blog post, a special
                    offer, or important news, push notifications ensure that the
                    message reaches the audience promptly.
                  </p>
                </div>
              </div>
              <div className="card image2">
                <img src={seo} alt="logo" className="seo" loading="lazy" />
                <div className="card-body">
                  <h1 className="card-title">Increased Website Traffic</h1>
                  <p className="card-info">
                    By notifying subscribers about new blog posts or content,
                    push notifications can drive immediate traffic to the
                    blogger's website. This can lead to higher views, increased
                    reader engagement, and potentially higher revenue through
                    ads or product sales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lp-about component-three">
          <div className="lef2">
            <div className="picarrange">
              <div className="card image3">
                <img src={team} alt="logo" className="team" loading="lazy" />
                <div className="card-body">
                  <h1 className="card-title">Improved User Engagement</h1>
                  <p className="card-info">
                    Push notifications can be personalized based on user
                    preferences. Bloggers can segment their audience and send
                    targeted notifications, increasing user engagement by
                    sending relevant content to specific groups of subscribers.
                  </p>
                </div>
              </div>
              <div className="card image4">
                <img
                  src={connectedworld}
                  alt="logo"
                  className="connectedworld"
                  loading="lazy"
                />
                <div className="card-body">
                  <h1 className="card-title">Building Community</h1>
                  <p className="card-info">
                    Bloggers can use push notifications to foster a sense of
                    community among their readers. Notifications can be used to
                    update subscribers about community events, discussions, or
                    exclusive content, encouraging readers to participate and
                    interact with each other.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="righ2">
            <h1>Get the Most Out From Us</h1>
            <br />
            <h5>
              Push notifications can be a powerful tool for bloggers to engage
              with their audience and drive traffic to their websites.
            </h5>
          </div>
        </div> */}

        <h3 className="mini_heading">Glimpse Of What Are We Upto</h3>

        <div className="img-one-container">
          <div data-aos="fade-right" className="img_parent">
            <img src={newCampaign} alt="New Campaign" loading="lazy" />
          </div>
          <div data-aos="fade-left" className="content-parent">
            <img
              className="icon-img"
              src={letsNotifyIcon}
              alt="New Campaign"
              loading="lazy"
              width={50}
              height={50}
            />
            <div className="content-section">
              <div className="mobile-div">
                <img
                  className="mobile-icon-img"
                  src={letsNotifyIcon}
                  alt="New Campaign"
                  loading="lazy"
                  width={40}
                  height={40}
                />
                <h4>Customize automated experiences effectively</h4>
              </div>
              <p>
                Craft exceptional customer journeys using smart automation and
                personalized, timely messaging based on real-time customer data
                and behavior.
              </p>
            </div>
          </div>
        </div>

        <div className="img-two-container">
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="img_parent">
            <img src={segments} alt="New Campaign" loading="lazy" />
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            className="content-parent">
            <img
              className="icon-img"
              src={letsNotifyIcon}
              alt="New Campaign"
              loading="lazy"
              width={50}
              height={50}
            />
            <div className="content-section">
              <div className="mobile-div">
                <img
                  className="mobile-icon-img"
                  src={letsNotifyIcon}
                  alt="New Campaign"
                  loading="lazy"
                  width={40}
                  height={40}
                />
                <h4>Precise, Swift Subscriber's Segmentation</h4>
              </div>
              <p>
                Swiftly group contacts for precise targeting based on real-time
                data and predictive analytics with Lets Notify's customizable
                segmentation tool
              </p>
            </div>
          </div>
        </div>

        <div className="img-three-container">
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="img_parent">
            <img src={automation} alt="New Campaign" loading="lazy" />
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            className="content-parent">
            <img
              className="icon-img"
              src={letsNotifyIcon}
              alt="New Campaign"
              loading="lazy"
              width={50}
              height={50}
            />
            <div className="content-section">
              <div className="mobile-div">
                <img
                  className="mobile-icon-img"
                  src={letsNotifyIcon}
                  alt="New Campaign"
                  loading="lazy"
                  width={40}
                  height={40}
                />
                <h4>Track, Engage, Notify, Convert, Win</h4>
              </div>
              <p>
                Trigger targeted push notifications leveraging website tracking
                data for timely, personalized engagement with your audience.
                Boost interaction effectively
              </p>
            </div>
          </div>
        </div>

        <div className="img-two-container">
          <div data-aos="fade-right" className="img_parent">
            <img src={abtesting} alt="New Campaign" loading="lazy" />
          </div>
          <div data-aos="fade-left" className="content-parent">
            <img
              className="icon-img"
              src={letsNotifyIcon}
              alt="New Campaign"
              loading="lazy"
              width={50}
              height={50}
            />
            <div className="content-section">
              <div className="mobile-div">
                <img
                  className="mobile-icon-img"
                  src={letsNotifyIcon}
                  alt="New Campaign"
                  loading="lazy"
                  width={40}
                  height={40}
                />
                <h4>A/B testing</h4>
              </div>
              <p>
                Eliminate the guesswork from your campaign content by real-time
                testing to determine the best-performing version (A or B) for
                your subscribers .
              </p>
            </div>
          </div>
        </div>

        {/* <div className="advantages">
          <div className="lef-grid">
            <h1>Benefits of Lets Notify</h1>
          </div>
          <div className="righ-grid">
            <div className="grid">
              <div className="promoting">
                <img src={promoting} alt="logo" className="promotingimg" />
                <div className="text-container">
                  <h4> Promoting Products and Services</h4>
                  <h6>
                    Bloggers boost sales with push notifications, directly
                    promoting products, services, and affiliates for increased
                    revenue.
                  </h6>
                </div>
              </div>

              <div className="promoting">
                <img src={feedback} alt="logo" className="promotingimg" />
                <div className="text-container">
                  <h4>Feedback and Surveys</h4>
                  <h6>
                    Bloggers use push notifications for audience surveys,
                    gathering opinions to enhance content and services
                    effectively.
                  </h6>
                </div>
              </div>

              <div className="promoting">
                <img src={socialmedia} alt="logo" className="promotingimg" />
                <div className="text-container">
                  <h4>Increasing Social Media Following</h4>
                  <h6>
                    Bloggers use push notifications to grow social media
                    presence, encouraging followers for a vibrant online
                    community and expanded reach.
                  </h6>
                </div>
              </div>

              <div className="promoting">
                <img src={eventpromotion} alt="logo" className="promotingimg" />
                <div className="text-container">
                  <h4>Event Promotion</h4>
                  <h6>
                    Bloggers use push notifications to alert subscribers about
                    webinars and events, ensuring maximum participation and
                    engagement.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        
        <LandingFooter />
      </div>
    </>
  );
}
export default LandingPage;

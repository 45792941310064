import React from "react";
import "./styles/LandingPage.css";
import { FaRegCopyright } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaDiscord, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter, FaRedditAlien   } from "react-icons/fa6";
import "./styles/LandingFooter.css";
import logo from "../../../assets/Images/logo.png";
import { unprotectedRoutes } from "constants/appRoutes";

function LandingFooter() {
  const currentYear = new Date().getFullYear();
  let navigate = useNavigate();

  const HandlerClick = () => {
    let userId = localStorage.getItem("user_id");
    if (userId == null) {
      navigate("/login");
    } else {
      navigate("/connectdashboard");
    }
  };

  return (
    <section className="mt-20 w-full bg-teal-100 ">
      <div>
        <div className="flex h-16 ml-2 items-center">
          <img
            src={logo}
            alt="logo"
            className="h-10 md:h-14"
            onClick={HandlerClick}
            loading="lazy"
          />
          <div className=" h-7  w-32 pl-2 font-bold text-xl ml-2 md:ml-3 border-l border-teal-800">
            Lets Notify
          </div>
        </div>

        <div className="w-full min-h-32 mb-4 justify-around font-semibold flex-col items-center md:flex-row flex text-black ">
          <div className="md:w-1/3 w-full pl-3 flex flex-col md:items-center justify-center gap-3 text-xl">
            <h1 className="md:text-[1.5vw] font-bold">Connect with us on</h1>
            <div className="flex gap-3">
              {/* <div className=" h-10 w-10 rounded-[50%] flex items-center justify-center  border-2 border-black md:hover:bg-black">
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to={"https://discord.com/channels/1227529106003070996/1227529106003070998"}>
                  <FaDiscord className="text-4xl p-2 w-full text-[#000] md:hover:text-white" />
                </Link>
              </div> */}
              {/*<div className=" h-10 w-10 rounded-[50%] flex items-center justify-center  border-2 border-black md:hover:bg-black">
                <FaFacebookF className="text-4xl p-2 w-full  text-[#000] md:hover:text-white" />
              </div>
              <div className=" h-10 w-10 rounded-[50%] flex items-center justify-center  border-2 border-black md:hover:bg-black">
              
                <RiInstagramFill className="text-4xl p-2 w-full text-[#000] md:hover:text-white" />
              </div>*/}
              <div className=" h-10 w-10 rounded-[50%] flex items-center justify-center  border-2 border-black md:hover:bg-black">
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to={"https://www.linkedin.com/company/lets-notify/"}>
                  <FaLinkedinIn className="text-4xl p-2 w-full text-[#000] md:hover:text-white" />
                </Link>
              </div>
              <div className=" h-10 w-10 rounded-[50%] flex items-center justify-center  border-2 border-black md:hover:bg-black">
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to={"https://twitter.com/letsnotify_"}>
                  <FaXTwitter className="text-4xl p-2 w-full text-[#000] md:hover:text-white" />
                </Link>
              </div>
              <div className=" h-10 w-10 rounded-[50%] flex items-center justify-center  border-2 border-black md:hover:bg-black">
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to={"https://www.reddit.com/user/letsnotify_/"}>
                  <FaRedditAlien   className="text-4xl p-2 w-full text-[#000] md:hover:text-white" />
                </Link>
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-full mt-7 md:mt-0 flex flex-col gap-1 pl-3 border-l font-semibold border-teal-800 ">
            <p className=" text-xl">Our Communities</p>
            <div className=" flex items-center gap-2">
              <FaDiscord size={20} />
              <Link
                target="_blank"
                rel="noreferrer"
                to="https://discord.com/channels/1227529106003070996/1227529106003070998">
                <p className="">Join Community</p>
              </Link>
            </div>
            {/* <div className=" w-20">
              <Link target="_blank" rel="noreferrer" to="/about">
                About us
              </Link>
             </div>}
            {/* <div className=" w-32">
            <Link
              target="_blank"
              rel="noreferrer" 
              to="https://discord.gg/qE2dKhArhn"
            >
             Join Community
             </Link></div>*/}
            {/* <ul>
                <li>Join Community</li>
                <li>About us</li>
              </ul> */}
          </div>
          <div className="md:w-1/3 w-full mt-7 md:mt-0 flex flex-col gap-1 pl-3 border-l border-teal-800 ">
            <p className=" text-xl">Contact Us</p>
            <div className="social">
              <a href="mailto:support@letsnotify.co.in">
                support@letsnotify.co.in
              </a>
              <div className="social-media-icons">
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to="https://www.linkedin.com/company/lets-notify/"></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-teal-800 text-white md:h-6  p-4 md:flex md:items-center md:justify-center">
        <FaRegCopyright className="text-xl inline" />
        <p className="inline">
          &nbsp; Copyright {currentYear} Letnotify. All rights reserve. Various
          trademarks held by their respective owners.&nbsp;
        </p>
        <Link
          style={{color: '#fff'}}
          className="underline text-blue-500 hover:text-white"
          rel="noopener noreferrer"
          to={unprotectedRoutes.termsAndCondition} target="_blank">
          Terms & conditions
        </Link>
      </div>
    </section>
  );
}

export default LandingFooter;

import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#058270",
      light: "#ccfbf1",
      dark: "",
    },
    secondary: {
      main: "#212529",
      dark: "#000000",
    },
    grey: {
      400: "#ececec",
    },
    background: {
      default: "#ffffff",
      secondary: "#F5F5F5",
      scroll: "#ecf0f1",
    },
    error: {
      main: "rgb(220 38 38)",
    },
  },

  typography: {
    h6: {
      color: "#fff",
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h5: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: "2rem",
    },
    h4: {
      color: "#058270",
    },
    h2: {
      color: "blue",
    },
    dashboardTitle: {
      color: "#058270",
      fontSize: "2rem",
      width: "100%",
      textAlign: "left",
    },
    black_h3: {
      color: "#000000",
      fontSize: "1.4rem",
    },
    black_h4: {
      color: "#000000",
    },
    black_h5: {
      color: "#000000",
      fontSize:"1rem",
    },
    black_h6: {
      color: "#000000",
      fontSize:".8rem",
    },

    white_h4: {
      color: "#ffffff",
    },
    subtitle2: {
      color: "#fff",
      fontSize: "1rem",
    },
    subtitle1: {
      color: "#058270",
    },
    body1: {
      color: "#058270",
    },
  },
  listItems: {
    buttonStyle: {
      padding: 1,
    },
    iconStyle: {
      minWidth: "10%",
      paddingRight: 2,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            color: "#000000",
          },
          "& label": {
            color: "#000000",
          },
        },
      },
    },
  },
  modules: {
    submitButtonsBox: {
      position: "relative",
      bottom: "0%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1.5rem",
      gap: "3rem",
    },
  },
});

export default theme;

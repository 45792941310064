import { Box, styled } from '@mui/material';

export const MainDashboard = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fbfbfb',
  paddingLeft: '1rem',
  paddingRight: '1rem',
}));

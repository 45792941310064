
export const unprotectedRoutes = {
  login: '/login',
  termsAndCondition: '/termsandcondition'
};

export const AppScreenRoute = '';

export const protectedRoutes = {
  app: '/*',
  home: AppScreenRoute + '/home',
  questions: AppScreenRoute + '/questions',
  createConnect: '/createConnect',
  dashboard: AppScreenRoute+'/dashboard',
  codeInjection: '/codeInjection',
  connectDashboard: AppScreenRoute + '/connectDashboard',
  templates: AppScreenRoute + "/templates",
  campaigns: AppScreenRoute + "/campaigns",
  subscribers: AppScreenRoute + "/subscribers",
  segments: AppScreenRoute + "/segments",
  profile: AppScreenRoute + "/profile"
};
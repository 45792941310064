import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import UnprotectedScreen from './pages/unprotected/index';
import reportWebVitals from './reportWebVitals';
import theme from './styles/app.theme';
import { toast, Toaster } from "react-hot-toast";
import { Provider } from 'react-redux';
import {persistor, store} from './redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} />
        <Toaster />
        <UnprotectedScreen />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Button } from '@mui/material';
import { styled } from '@mui/system';

const RoundButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  textTransform: "none",
  color: theme.palette.secondary.main,
  fontWeight: "bold",
  fontSize: "1rem",
  borderRadius: "1.5rem",
  padding: "0.5rem 4rem",
  width: "100%",
  position: "relative",
  overflow: "hidden",
  '&:hover' : {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    transition: "0.3s ease-out",
    transform: 'scaleX(1)',
  }
}));

export const RoundedButton = ({ title, style, sx, onClick }) => {
  return (
    <RoundButton style={style} sx={sx} onClick={onClick}>
      {title}
    </RoundButton>
  );
};

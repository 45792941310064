import ArticleIcon from '@mui/icons-material/Article';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import SegmentIcon from '@mui/icons-material/Segment';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MessageIcon from '@mui/icons-material/Message';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
  useTheme,
  Avatar,
  IconButton,
  Popover,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logoSrc from 'assets/Images/logo.png';
import { protectedRoutes } from 'constants/appRoutes';
import { unprotectedRoutes } from 'constants/appRoutes';
import { useDispatch } from 'react-redux';
import { setAuthDetails } from '../../redux/reducers/authReducer';

const userDetails = JSON.parse(localStorage.getItem("userDetails"));
const name = userDetails?.fullname || 'Unknown';
const email = userDetails?.email;
const firstLetter = name.charAt(0);

const SideMenuItems = [
  {
    name: 'Dashboard',
    icon: <HomeIcon />,
    link: protectedRoutes.dashboard,
  },
  {
    name: 'Web Notifications',
    icon: <SettingsInputComponentIcon />,
    child: [
      {
        name: 'Connections',
        icon: <SettingsInputComponentIcon />,
        link: protectedRoutes.connectDashboard,
      },
      {
        name: 'Templates',
        icon: <ArticleIcon />,
        link: protectedRoutes.templates,
      },
      {
        name: 'Segments',
        icon: <SegmentIcon />,
        link: protectedRoutes.segments,
      },
      {
        name: 'Campaigns',
        icon: <SendIcon />,
        link: protectedRoutes.campaigns,
      },
      {
        name: 'Subscribers',
        icon: <GroupIcon />,
        link: protectedRoutes.subscribers,
      },
    ],
  },
  {
    name: 'Realtime Notifications',
    icon: <SettingsInputComponentIcon />,
    child: [
      {
        name: 'Coming Soon..',
        icon: <GroupIcon />,
        // link: protectedRoutes.subscribers,
      },
    ],
  },
];
const ProfileMenuItems = [
  {
    name: 'Profile',
    icon: <PersonIcon />,
    link: protectedRoutes?.profile,
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    link: '',
  },
  {
    name: 'Help',
    icon: <QuestionMarkIcon />,
    link: '',
  },
  {
    name: 'Leave a Review',
    icon: <MessageIcon />,
    link: '',
  },
  {
    name: 'Join Community',
    icon: <Diversity3Icon />,
    link: '',
  },
  {
    name: 'Terms And Conditions',
    icon: <StickyNote2OutlinedIcon />,
    link: unprotectedRoutes.termsAndCondition,
  },
  {
    name: 'Log Out',
    icon: <LogoutIcon />,
    link: protectedRoutes.login,
  },
];

const Drawer = styled('div')(({ theme }) => ({
  height: '100%',
  width: "100%",
  boxShadow: '0px 0px 8px gray',
  padding: 1
}));

export const AppSidebar = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const [openIndex, setOpenIndex] = React.useState(1);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleClick = (index) => {
    const path = SideMenuItems[index]?.link;
    if (path) {
      handleNavigate(path);
      if (toggleSidebar) toggleSidebar();
    } else {
      setOpenIndex(openIndex === index ? null : index);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleNavigate = (path) => {
    navigate(path);
    if (toggleSidebar) toggleSidebar();
  };

  const handleLogout = () => {
    localStorage.clear('token');
    const payload = {
      token: null,
      authUser: false,
    };
    dispatch(setAuthDetails(payload));
    navigate('/login');
  };

  return (
    <Drawer sx={{ position: 'relative', height: '100%' }}>
      <Button
        sx={{
          display: { xs: 'block', md: 'none' },
          position: 'absolute',
          top: 10,
          right: 0,
          zIndex: 10,
          cursor: 'pointer',
        }}
        onClick={toggleSidebar}
      >
        <CloseIcon />
      </Button>
      <Box display='flex' justifyContent='center' alignItems='center' py={1}>
        <img src={logoSrc} width={30} height={30} alt='Logo'></img>
        <Typography
          color={'black'}
          variant='h6'
          noWrap
          component='div'
          sx={{
            mx: 2,
            color: 'rgb(5, 130, 112)',
          }}
        >
          Lets Notify
        </Typography>
      </Box>
      <Divider />
      <List>
        {SideMenuItems.map((eachItem, index) => (
          <React.Fragment key={index}>
            <ListItem disablePadding>
              <ListItemButton
                selected={isActive(`/${eachItem.name.toLowerCase()}`)}
                sx={{
                  minHeight: 30,
                  marginTop: 0.5,
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor:
                    openIndex === index && theme.palette.grey[400],
                }}
                onClick={() => handleClick(index)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    color:
                      isActive(`/${eachItem.name.toLowerCase()}`) ||
                      index === openIndex
                        ? theme.palette.primary.main
                        : theme.palette.grey,
                  }}
                >
                  {eachItem.icon}
                </ListItemIcon>
                <ListItemText
                  primary={eachItem.name}
                  primaryTypographyProps={{
                    color:
                      isActive(`/${eachItem.name.toLowerCase()}`) ||
                      index === openIndex
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                        fontSize: '0.875rem'
                  }}
                  sx={{
                    opacity: 1,
                    marginX: 2,
                  }}
                />
                {eachItem.child && eachItem.child.length > 0 && (
                  <React.Fragment>
                    {openIndex === index ? <ExpandLess /> : <ExpandMore />}
                  </React.Fragment>
                )}
              </ListItemButton>
            </ListItem>
            {eachItem.child && eachItem.child.length > 0 && (
              <Collapse in={openIndex === index} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {eachItem.child.map((childItem, childIndex) => (
                    <ListItemButton
                      key={childIndex}
                      onClick={() => handleNavigate(childItem.link)}
                      sx={{
                        backgroundColor: isActive(childItem.link)
                          ? 'rgba(5, 130, 112, 0.08)'
                          : theme.palette.grey,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: isActive(childItem.link)
                            ? theme.palette.primary.main
                            : theme.palette.grey,
                        }}
                      >
                        {childItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{fontSize: '0.875rem', color: isActive(childItem.link)
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,}}
                        primary={childItem.name}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>

      <Box sx={{
        position: 'absolute',
        bottom: 10,
        left: 0,
        right:0,
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        gap: 1,
        padding:1,
        margin: 'auto',
      }}>
        <Avatar  sx={{color: "background.default", bgcolor: "primary.main", width: '2rem', height: '2rem'}}>{firstLetter}</Avatar>
        <Box sx={{display: 'flex', flexDirection: 'column', width: 'auto'}}>
        <Typography fontSize={'0.875rem'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>{name}</Typography>
        </Box>
        <IconButton size='small' aria-describedby={id} onClick={handlePopover}>
          <ChevronRightIcon fontSize='24' />
        </IconButton>
      </Box>

      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: '20%',
            border: `1px solid ${theme.palette.primary.grey}`,
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <Box sx={{ borderRadius: '1rem', border: '2px solid redd' }}>
          <List sx={{ borderRadius: '1rem', border: '2px solid redd' }}>
            {ProfileMenuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem sx={{ padding: 0 }}>
                  <ListItemButton
                    onClick={() => {
                      if (item.link === protectedRoutes.logout) {
                        handleLogout();
                        handleClose();
                      } else {
                        navigate(item.link);
                        handleClose();
                      }
                    }}
                    sx={theme.listItems?.buttonStyle}
                    selected={isActive(item.link)}
                  >
                    <ListItemIcon sx={theme.listItems?.iconStyle}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
                {index !== ProfileMenuItems.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Popover>
    </Drawer>
  );
};

import React, { useState } from "react";
import { MdMenu } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/Images/logo.png";
import { RxCross2 } from "react-icons/rx";

const LandingPageHeader = () => {
  let navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const HandlerClick = () => {
    let userId = localStorage.getItem("user_id");
    if (userId == null) {
      navigate("/");
      setMenuOpen(false);
    } else {
      navigate("/connectdashboard");
      setMenuOpen(false);
    }
  };

  return (
    <div className=" w-[99vw] h-16 md:h-24 flex items-center pl-2 pr-2 md:pl-14 md:pr-14 justify-between backdrop-blur-sm z-10 fixed top-0 ">
      {/* <Header /> */}
      <div className="flex h-10  items-center">
        <img
          src={logo}
          alt="logo"
          className="h-8 md:h-12"
          onClick={HandlerClick}
          loading="lazy"
        />
        <div className=" h-5 md:h-7 pl-1  w-32 md:pl-2 font-bold text-sm md:text-xl ml-1 md:ml-3 border-l border-teal-800">
          Lets Notify
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-lg flex items-center gap-3">
          {/* <h4>About Us</h4> */}
          {/* <h4>Help</h4> */}
          <div className=" p-2 nav-button text-white text-sm bg-teal-600 rounded-md">
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://docs.google.com/forms/d/e/1FAIpQLScAAYJZEj2FsUTrJ8RHNtEUXbPPcLEA87bqV8l8XiPI3yZrkQ/viewform">
              Get Early Access
            </Link>
          </div>
          <Link
            target="_blank"
            className="text-sm hidden md:block font-semibold"
            rel="noreferrer"
            to="https://discord.gg/qE2dKhArhn">
            Join Community
          </Link>
        </div>
      </div>
      {/* <div className="login-signup">
          <button
            className="lp-login"
            onClick={() => {
              navigate('/login');
            }}
          >
            Login
          </button>
          <button
            className="header-signup"
            onClick={() => {
              navigate('/signup');
            }}
          >
            Sign Up
          </button>
        </div> */}

      {/* <MdMenu />
      <MdMenuOpen />       */}
      {/*<div className="mobile-menu">
        {menuOpen ? (
          //   <div
          //     onClick={() => {
          //       setMenuOpen(false);
          //     }}
          //   >
          //     <MdMenuOpen size={35} />
          //   </div>
          <></>
        ) : (
          <div className="header-mob-menu">
            <Link
              target="_blank"
              rel="noreferrer"
              className="early-access"
              to="https://docs.google.com/forms/d/e/1FAIpQLScAAYJZEj2FsUTrJ8RHNtEUXbPPcLEA87bqV8l8XiPI3yZrkQ/viewform"
            >
              Get Early Access
            </Link>*/}
      <div
        className=" block md:hidden"
        onClick={() => {
          setMenuOpen(true);
        }}>
        <MdMenu size={35} />
      </div>

      {menuOpen ? (
        <div className="h-[100vh] absolute top-0 left-0 w-full bg-[#ECECEC]">
          <div className="flex items-center justify-between h-16 pl-2 pr-2">
            <div className="flex h-10  items-center">
              <img
                src={logo}
                alt="logo"
                className="h-8 md:h-12"
                onClick={HandlerClick}
                loading="lazy"
              />
              <div className=" h-5 md:h-7 pl-1  w-32 md:pl-2 font-bold text-md md:text-xl ml-1 md:ml-3 border-l border-teal-800">
                Lets Notify
              </div>
            </div>
            <div
              className="cancel-icon"
              onClick={() => {
                setMenuOpen(false);
              }}>
              <RxCross2 size={35} />
            </div>
          </div>
          <div className="w-full mt-14 text-2xl flex flex-col items-center">
            {/* <li>About Us</li> */}
            {/* <li>Help</li> */}
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://discord.gg/qE2dKhArhn">
              <p>Join Community</p>
            </Link>
            {/* <li>Login</li>
                <li>Sign Up</li> */}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LandingPageHeader;
